import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-marketoutlook',
  templateUrl: './marketoutlook.component.html',
  styleUrls: ['./marketoutlook.component.scss'],
})
export class MarketoutlookComponent implements OnInit {
  lanedata:any
  selectedtype="1"
  constructor(public service : WebapiService) { 
    this.lanedata=this.service.marketoutlooktitle

    //this.getgraph()
  }
  fuel={
    "changpoint":'',
    "seasonality":''
  }
  ngOnInit() {}

  
  }
