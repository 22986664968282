import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-shipperdetailscob',
  templateUrl: './shipperdetailscob.component.html',
  styleUrls: ['./shipperdetailscob.component.scss'],
})
export class ShipperdetailscobComponent implements OnInit {

  lane;
  quarter;
  lc:any;
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  constructor(public service:WebapiService) { }
  ngOnInit(): void {
    console.log(this.lane)
    console.log(this.quarter)

    this.get_airdashboard_shpipper();
  }

  loader=false;
  // ngOnInit() {
  //   // console.log(this.tdata)
  //   console.log(this.lane)
  //   console.log(this.quarter)

  //   this.get_airdashboard_shpipper();
  // }

  // get_perc(x){

  //   return ((x.COB_WEIGHT_CHARGEABLE/this.total)*100).toFixed(2);
  // }

  sheiperdetails:any=[]
  shipperview:any=[]
  distinctpartner:any=[]
  get_airdashboard_shpipper(){
    this.loader=true;

    this.service.postData({lane:this.lane,quarter:this.quarter}, 'get_demand_sensing_magic_network_report_shipper').then((result) => {
      console.log(result)
      var data:any=result;
      this.sheiperdetails=JSON.parse(data.data)
 
      console.log(this.sheiperdetails);

      var distinctshipper = this.sheiperdetails
      .map((item) => item.SHIPPER_NAME)
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      );

      var distinctpartner = this.sheiperdetails
      .map((item) => item.PARTNER)
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      );


      this.distinctpartner=distinctpartner;
      distinctshipper.forEach(shipper => {
      

        var partnerlist:any=[]
        var total=0;
        total=0;
        distinctpartner.forEach(partner => {
          
      

           var p = this.sheiperdetails.filter(function(item:any){return item.SHIPPER_NAME===shipper && item.PARTNER==partner})

             if(p.length>0)
               total=total+p[0].COB_WEIGHT_CHARGEABLE_x;
             partnerlist.push({partner:partner,data:p})
          });

        this.shipperview.push({shipper:shipper,total:total,partner:partnerlist})


      });

      console.log(this.shipperview);
      this.loader=false;
    });
  }

  saveAsCSVshipperdata() {
   
    // Convert array of objects to array of arrays


   
    const data: Array<Array<string>> = [
     
  ];

  var row:any=[]
  
  row.push('SHIPPER_NAME')
  this.distinctpartner.forEach(elementp => {
    
    row.push(elementp)
    row.push(elementp + "(SUN-TUE)")
    row.push(elementp+ "(WED-SAT)")

  });

  row.push('Grand Total')
  this.distinctpartner.forEach(elementp => {    
    row.push(elementp+'(%)')
  });

  data.push(row);
  
  this.shipperview.forEach(element => {
  
    var row:any=[]
    row.push(element.shipper)
    element.partner.forEach(elementp => {
      console.log(elementp)
      if(elementp.data.length>0)
        {
        row.push(elementp.data[0].COB_WEIGHT_CHARGEABLE_x)
        row.push(elementp.data[0].COB_WEIGHT_CHARGEABLE_y)
        row.push(elementp.data[0].COB_WEIGHT_CHARGEABLE_x-elementp.data[0].COB_WEIGHT_CHARGEABLE_y)
        }
        else
        {
          row.push(0)
          row.push(0)
          row.push(0)
        }
    });

    row.push(element.total)
    element.partner.forEach(elementp => {
      if(elementp.data.length>0)
        {
      row.push((elementp.data[0].COB_WEIGHT_CHARGEABLE_x/element.total)*100)
        }
        else
        row.push(0)

  });

   data.push(row);
    
  });
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
  
  workbook.SheetNames.push("Filtered Data");
  workbook.Sheets["Filtered Data"] = worksheet;
  
      XLSX.writeFile(workbook,this.quarter+'_'+this.lane+'_shipperdetailsdata'+".xlsx");
  
   
  }


}
