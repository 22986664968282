import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-othercarrier',
  templateUrl: './othercarrier.component.html',
  styleUrls: ['./othercarrier.component.scss'],
})
export class OthercarrierComponent implements OnInit {

  tdata;
  date;
  total;
  lc:any;
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  constructor() { }

  ngOnInit() {
    console.log(this.tdata)
    console.log(this.date)
    console.log(this.total)

  }

  get_perc(x){

    return ((x.COB_WEIGHT_CHARGEABLE/this.total)*100).toFixed(2);
  }

}
