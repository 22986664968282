import { height, width } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { style } from '@angular/animations';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  @ViewChild('myElement') myElement!: ElementRef;

  @ViewChild('elmentblog') elmentblog!: ElementRef;

  
  selectedblock:any;
  selectedel:any;
  contentlist:any=[];
  bloglist:any=[];
  flag='l'
  element = {
   element:'',
   style:'',
   src:'', 
  }

  report:any={
    title:'Enter Report title here',
    updatedby:'mamta.kumar@coboticsllc.com',
    updateddate:'16th july',
    element:[],
    html:''
  }

  blocklist:any =[];
  
  blocks:any = {
    size:12,
    styles: {
      padding:'10px',
      fontsize:'12px',
      color:'red',
    },
    type:'p',
    innerHTML:""
  }
  base64Image: string | ArrayBuffer | null = null;

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.base64Image = reader.result; // This will contain the Base64 string
        this.selectedel.src=this.base64Image;

      };

      reader.readAsDataURL(file); // Convert the file to Base64
    }
  }
  
  sett(e){
    this.flag=e;
    console.log(this.flag);
    if(this.flag=='l'){

      if(this.bloglist.length>0)
        this.setblog(this.bloglist[0])
    }
  }
  tableconfig={
    type:'table',
    backgroundColor:'#fff',
    fontSize:'14px',
    fontweight:"bold",
    width:"100%",
    color:'black',
    col:4,
    row:3
  }

template="";

  // sample template 
  constructor(private renderer: Renderer2,public router:Router,public web:WebapiService) { 

    var list= localStorage.getItem('bloglist');

    // const blob = new Blob([list], { type: 'text/plain' });
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');

    // a.href = url;
    // a.download = 'sample.txt';
    // a.click();

    // // Clean up and revoke the object URL
    // URL.revokeObjectURL(url);
  
    this.web.readFile('assets/sample.txt').subscribe(
      (data) => {
        this.template=data;
        if(list)
          {

            this.bloglist=JSON.parse(list);
            console.log(this.bloglist.length)
            if(this.bloglist.length==0)
            {

              this.bloglist=JSON.parse(this.template);
              localStorage.setItem('bloglist',JSON.stringify(this.bloglist));
            }
            console.log(this.bloglist);
      
          }else{
            this.bloglist=JSON.parse(this.template);
            localStorage.setItem('bloglist',JSON.stringify(this.bloglist));
      
          }
      },
      (error) => {
        console.error('Error reading file:', error);
      }
    );
    
    this.web.postData({},'generate_file_api').then(result=>{

       console.log(result);
    });
    

    var blocks:any = {
      size:12,
      element: [
        {
          type:'title',
          backgroundColor:'#fff',
          fontSize:'28px',
          fontweight:"bold",
          color:'blue',
          text:'type title here..',
        }
      ]

    }

   
    this.blocklist.push(blocks);

    var blocks:any = {
      size:6,
      styles: {
        padding:'10px',
        fontsize:'28px',
        color:'red',
      },
      
      element: [
        {
          type:'img',
          width:"100%",
          height:"auto",
          src:'https://www.researchgate.net/profile/Mariofanna-Milanova/publication/325456206/figure/fig1/AS:668689657516040@1536439446153/Sentiment-trend-graph-for-product-labelled-natural-balance.png',
        }
      ]
      

    }
    this.blocklist.push(blocks);


    var blocks:any = {
      size:6,
      styles: {
        padding:'10px',
        fontsize:'12px',
        color:'red',
      },
      element: [
        {
          type:'title',
          backgroundColor:'#fff',
          fontSize:'18px',
          fontweight:"bold",
          color:'blue',
          text:'type title here..',
        },
        {
          type:'list',
          backgroundColor:'#fff',
          fontSize:'14px',
          fontweight:"bold",
          color:'black',
          text:'type title here..',
        }
      
      ]
    }
    this.blocklist.push(blocks);


    var blocks:any = {
      size:12,
      styles: {
        padding:'10px',
        fontsize:'12px',
        color:'red',
      },
      element: [
        {
          type:'title',
          backgroundColor:'#fff',
          fontSize:'18px',
          fontweight:"bold",
          color:'blue',
          text:'type title here..',
        },
        {
          type:'table',
          backgroundColor:'#fff',
          fontSize:'14px',
          fontweight:"bold",
          width:"100%",
          color:'black',
          col:4,
          row:3
        }
      
      ]
    }
    this.blocklist.push(blocks);

    var blocks:any = {
      size:6,
      styles: {
        padding:'10px',
        fontsize:'12px',
        color:'red',
      },
      element: [
        {
          type:'title',
          backgroundColor:'#fff',
          fontSize:'18px',
          fontweight:"bold",
          color:'blue',
          text:'type title here..',
        },
        {
          type:'text',
          backgroundColor:'#fff',
          fontSize:'14px',
          fontweight:"bold",
          color:'black',
          text:'type title here..',
        }
      
      ]
    }
    this.blocklist.push(blocks);

   
    console.log(this.blocklist);
  }

  delete_all(){
    this.bloglist=[];
    localStorage.setItem('bloglist',JSON.stringify(this.bloglist));

  }

  imgflag=false;
  updateimage(x,e){

    this.selectedblock=x;
    this.selectedel=e;
    this.imgflag=true;;

    
  }
  addtable(){
    var blocks:any = {
      size:12,
      styles: {
        padding:'10px',
        fontsize:'12px',
        color:'red',
      },
      element: [
        {
          type:'title',
          backgroundColor:'#fff',
          fontSize:'18px',
          fontweight:"bold",
          color:'blue',
          text:'type title here..',
        },
        this.tableconfig
      
      ]
    }
    this.report.element.push(blocks);
  }
  addblock(v){

    if(v==0){

      var blocks:any = {
        size:12,
        styles: {
          padding:'10px',
          fontsize:'12px',
          color:'red',
        },
        element: [
          {
            type:'title',
            backgroundColor:'#fff',
            fontSize:'18px',
            fontweight:"bold",
            color:'blue',
            text:'type title here..',
          },
          {
            type:'list',
            backgroundColor:'#fff',
            fontSize:'14px',
            fontweight:"bold",
            color:'black',
            text:'type title here..',
          }
        
        ]
      }
      this.report.element.push(blocks);
    }else if(v==1){

      var blocks:any = {
        size:6,
        styles: {
          padding:'10px',
          fontsize:'28px',
          color:'red',
        },
        
        element: [
          {
            type:'img',
            width:"100%",
            height:"auto",
            src:'https://www.researchgate.net/profile/Mariofanna-Milanova/publication/325456206/figure/fig1/AS:668689657516040@1536439446153/Sentiment-trend-graph-for-product-labelled-natural-balance.png',
          }
        ]
        
  
      }
      this.report.element.push(blocks);
  
  
      var blocks:any = {
        size:6,
        styles: {
          padding:'10px',
          fontsize:'12px',
          color:'red',
        },
        element: [
          {
            type:'title',
            backgroundColor:'#fff',
            fontSize:'18px',
            fontweight:"bold",
            color:'blue',
            text:'type title here..',
          },
          {
            type:'list',
            backgroundColor:'#fff',
            fontSize:'14px',
            fontweight:"bold",
            color:'black',
            text:'type title here..',
          }
        
        ]
      }
      this.report.element.push(blocks);
  

    }else if(v==3){
      
      var blocks:any = {
        size:6,
        styles: {
          padding:'10px',
          fontsize:'12px',
          color:'red',
        },
        element: [
          {
            type:'title',
            backgroundColor:'#fff',
            fontSize:'18px',
            fontweight:"bold",
            color:'blue',
            text:'type title here..',
          },
          {
            type:'list',
            backgroundColor:'#fff',
            fontSize:'14px',
            fontweight:"bold",
            color:'black',
            text:'type title here..',
          }
        
        ]
      }
      this.report.element.push(blocks);

      var blocks:any = {
        size:6,
        styles: {
          padding:'10px',
          fontsize:'28px',
          color:'red',
        },
        
        element: [
          {
            type:'img',
            width:"100%",
            src:'https://www.researchgate.net/profile/Mariofanna-Milanova/publication/325456206/figure/fig1/AS:668689657516040@1536439446153/Sentiment-trend-graph-for-product-labelled-natural-balance.png',
          }
        ]
        
  
      }
      this.report.element.push(blocks);
  
  
     
  
    }
    else if(v==2) {
      var blocks:any = {
        size:12,
        styles: {
          padding:'10px',
          fontsize:'28px',
          color:'red',
        },
        
        element: [
          {
            type:'img',
            width:"100%",
            height:"auto",
            src:'https://www.researchgate.net/profile/Mariofanna-Milanova/publication/325456206/figure/fig1/AS:668689657516040@1536439446153/Sentiment-trend-graph-for-product-labelled-natural-balance.png',
          }
        ]
        
  
      }
      this.report.element.push(blocks);

    }else if(v==4){
      var blocks:any = {
        size:8,
        styles: {
          padding:'10px',
          fontsize:'12px',
          color:'red',
        },
        element: [
          {
            type:'title',
            backgroundColor:'#fff',
            fontSize:'18px',
            fontweight:"bold",
            color:'blue',
            text:'type title here..',
          },
          {
            type:'text',
            backgroundColor:'#fff',
            fontSize:'14px',
            fontweight:"bold",
            color:'black',
            text:'type title here..',
          }
        
        ]
      }
      this.report.element.push(blocks);

      var blocks:any = {
        size:4,
        styles: {
          padding:'10px',
          fontsize:'12px',
          color:'red',
        },
        element: [
          {
            type:'title',
            backgroundColor:'#fff',
            fontSize:'18px',
            fontweight:"bold",
            color:'blue',
            text:'type title here..',
          },
          {
            type:'text',
            backgroundColor:'#fff',
            fontSize:'14px',
            fontweight:"bold",
            color:'black',
            text:'type title here..',
          }
        
        ]
      }
      this.report.element.push(blocks);
    }
  }
  adddefault(){
    this.report.element=JSON.parse(JSON.stringify(this.blocklist));
    this.updateflag=false;

  }
  generateSequence(n) {
    const sequence = [];
    for (let i = 1; i <= n; i++) {
      sequence.push(i);
    }
    return sequence;
  }
  setblog(x){
    console.log(x)
    this.elmentblog.nativeElement.innerHTML=x.html;
    

  }
  updateflag=false;
  updateblog(x,i){

    console.log(x);
    this.report=x;
    this.updateflag=true;
    this.selectedindex=i;


  }

  clearall(){
    this.report={
      title:'Enter Report title here',
      updatedby:'mamta.kumar@coboticsllc.com',
      updateddate:'16th july',
      element:[],
      html:''
    }
    this.updateflag=false;

  
  }
  selectedindex=0;
  GOtoNExtPage_1() {
    
    this.router.navigateByUrl('/dashboard');
  }
  getInnerHTML() {
    
    this.report.html =this.myElement.nativeElement.innerHTML;

    
   
    if(this.updateflag)
    {
      
      this.bloglist[this.selectedindex]=this.report;
      console.log(this.bloglist)
      localStorage.setItem('bloglist',JSON.stringify(this.bloglist));

    }else
    {
      this.bloglist.push(this.report)
      localStorage.setItem('bloglist',JSON.stringify(this.bloglist));
    }


  }
  addheading1(value,style){
    this.element.element="<h1>"+value+"</h1>";
    this.element.style="color:blue";
    this.contentlist.push(this.element)
  }
  addheading2(value,style){
    this.element.element="<h2>"+value+"</h2>";
    this.element.style="color:blue";
    this.contentlist.push(this.element)
  }
  addheading3(value,style){
    this.element.element="<h3>"+value+"</h3>";
    this.element.style="color:blue";
    this.contentlist.push(this.element)
  }
  addheading4(value,style){
    this.element.element="<h4>"+value+"</h4>";
    this.element.style="color:blue";
    this.contentlist.push(this.element)
  }
  addheading5(value,style){
    this.element.element="<h5>"+value+"</h5>";
    this.element.style="color:blue";
    this.contentlist.push(this.element)
  }
  addheading6(value,style){
    this.element.element="<h6>"+value+"</h6>";
    this.element.style="color:blue";
    this.contentlist.push(this.element)
  }
  addimg(src,style){
    
    this.element.element="<img src='"+src+"' style='height:200px;'>";
    this.contentlist.push(this.element)
  }
  addpara(value,style){
    this.element.element="<p>"+value+"</p>";
    this.element.style="color:black";
    this.contentlist.push(this.element)
  }
  addlist(value,style){
    this.element.element="<ul><li>"+value+"</li></ul>";
    this.element.style="color:black";
    this.contentlist.push(this.element)
  }
  addtext(value,style){
    this.element.element="<span>"+value+"</span>";
    this.element.style="color:black";
    this.contentlist.push(this.element)
  }
  ngOnInit(): void {

  }


}
