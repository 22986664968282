import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-demandsensingspenddashbaord',
  templateUrl: './demandsensingspenddashbaord.component.html',
  styleUrls: ['./demandsensingspenddashbaord.component.scss'],
})
export class DemandsensingspenddashbaordComponent implements OnInit {

  constructor(public service:WebapiService) { 

    this.getconfig();

  }

  ngOnInit() {

   
  }

  getconfig(){
    this.service.postData({}, 'getpartnerconfig_Spend').then((result) => {

      console.log(result);
      var temp: any = result;
    });
  }

}
