import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.scss'],
})
export class RemarksComponent implements OnInit {
  lane;
  week;
  remarks:any="";
  remarklist:any=[]
  role=""
  constructor(public webapi:WebapiService) { 
    this.role=localStorage.getItem('role');

   
  }
  loadermsg="";

  getremarks(){
    this.loadermsg="fetching...";
    this.webapi.postData({lane:this.lane,week:this.week},"get_remark_demand_filter").then(res=>{

      this.loadermsg=""
      var result:any=res;
      console.log(res);
      this.remarklist=result.data;
    });
  }
  addremark(){

    this.loadermsg="adding your remarks..";

    var email =localStorage.getItem('email');

    var obj = {
      lane:this.lane,
      week:this.week,
      updatedby:email,
      remarks:this.remarks
    }
    console.log(obj);

    this.webapi.postData(obj,"add_remark_demand").then(res=>{
      this.loadermsg=""

      console.log(res);
      this.remarks="";
      this.getremarks();
    });
  }
  delete(id){
    this.loadermsg="deleting  remark..";

    this.webapi.postData({id:id},"del_remark_demand").then(res=>{
      this.loadermsg=""

      var result:any=res;
      console.log(res);
      this.getremarks();

    });
  }
  ngOnInit() {

    console.log(this.lane);
    console.log(this.week);
    this.getremarks();

  }

}
